import React from "react";

/** Nextjs */
import dynamic from "next/dynamic";

/** MUI */
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";

/** UI */
const Header = dynamic(() => import("./components/Header"));

const useStyles = makeStyles((theme) => ({
    main: {
        // backgroundColor: "#FFFFFF",
        minHeight: "calc(100vh)",
    },
}));

const HomeLayout = ({ children }) => {
    const classes = useStyles();

    return (
        <>
            <CssBaseline />

            <Container maxWidth={false} className={classes.root} disableGutters>
                <Header />
                <main className={classes.main}>{children}</main>
            </Container>
        </>
    );
};

export default HomeLayout;
